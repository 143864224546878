import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  {
    pid: 0,
    risk: 5,
    lpSymbol: 'MSC-BNB LP',
    lpAddresses: {
      97: '',
      56: '0xE21A6B0428B8eb3cD60A6764FF8033346EB51143',
    },
    tokenSymbol: 'MSC',
    tokenAddresses: {
      97: '',
      56: '0x9c9ac8b098a7d47ed1834599ce2dc29cb94103e9',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 1,
    risk: 5,
    lpSymbol: 'MSC-BUSD LP',
    lpAddresses: {
      97: '',
      56: '0xf33ea56898d4c0F7ae6348ceffe12953611F1ad1',
    },
    tokenSymbol: 'MSC',
    tokenAddresses: {
      97: '',
      56: '0x9c9ac8b098a7d47ed1834599ce2dc29cb94103e9',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 2,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'BUSD',
    lpAddresses: {
      97: '',
      56: '0xf33ea56898d4c0F7ae6348ceffe12953611F1ad1', // bnb - busd lp
    },
    tokenSymbol: 'BUSD',
    tokenAddresses: {
      97: '',
      56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 3,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'TRX',
    lpAddresses: {
      97: '',
      56: '0xb5D108578Be3750209d1b3A8f45FFee8C5a75146', // trx - busd lp
    },
    tokenSymbol: 'TRX',
    tokenAddresses: {
      97: '',
      56: '0x85eac5ac2f758618dfa09bdbe0cf174e7d574d5b',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 4,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'MSC',
    lpAddresses: {
      97: '',
      56: '0xf33ea56898d4c0F7ae6348ceffe12953611F1ad1', // msc - busd lp
    },
    tokenSymbol: 'MSC',
    tokenAddresses: {
      97: '',
      56: '0x9c9ac8b098a7d47ed1834599ce2dc29cb94103e9',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 5,
    risk: 5,
    lpSymbol: 'DOGE-BNB LP',
    lpAddresses: {
      97: '',
      56: '0xac109C8025F272414fd9e2faA805a583708A017f',
    },
    tokenSymbol: 'DOGE',
    tokenAddresses: {
      97: '',
      56: '0xbA2aE424d960c26247Dd6c32edC70B295c744C43',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 6,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'DOGE',
    lpAddresses: {
      97: '',
      56: '0xE27859308ae2424506D1ac7BF5bcb92D6a73e211', // doge - busd lp
    },
    tokenSymbol: 'DOGE',
    tokenAddresses: {
      97: '',
      56: '0xba2ae424d960c26247dd6c32edc70b295c744c43',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 7,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'PSG',
    lpAddresses: {
      97: '',
      56: '0x132BDb6374B1273Baabe542e814eAe29AadD45f9', // psg - busd lp
    },
    tokenSymbol: 'PSG',
    tokenAddresses: {
      97: '',
      56: '0xBc5609612b7C44BEf426De600B5fd1379DB2EcF1',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 8,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'AXS',
    lpAddresses: {
      97: '',
      56: '0x341581419b14E71C126F4a6e3B37d25D44A12A63', // axs - busd lp
    },
    tokenSymbol: 'AXS',
    tokenAddresses: {
      97: '',
      56: '0x715d400f88c167884bbcc41c5fea407ed4d2f8a0',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 9,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'ADA',
    lpAddresses: {
      97: '',
      56: '0x1E249DF2F58cBef7EAc2b0EE35964ED8311D5623', // ada - busd lp
    },
    tokenSymbol: 'ADA',
    tokenAddresses: {
      97: '',
      56: '0x3ee2200efb3400fabb9aacf31297cbdd1d435d47',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 10,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'XRP',
    lpAddresses: {
      97: '',
      56: '0x8339CfC9002d1ecBB23b9DE95CF17AbF0A9c2ea8', // xrp - busd lp
    },
    tokenSymbol: 'XRP',
    tokenAddresses: {
      97: '',
      56: '0x1d2f0da169ceb9fc7b3144628db156f3f6c60dbe',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 11,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'DOT',
    lpAddresses: {
      97: '',
      56: '0x9fDaaB9312084298d210B8789629D3054230e998', // xrp - busd lp
    },
    tokenSymbol: 'DOT',
    tokenAddresses: {
      97: '',
      56: '0x7083609fce4d1d8dc0c979aab8c869ea2c873402',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 12,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'UNI',
    lpAddresses: {
      97: '',
      56: '0x0E91275Aec7473105c8509BC41AE54b8FE8a7Fc3', // uni - busd lp
    },
    tokenSymbol: 'UNI',
    tokenAddresses: {
      97: '',
      56: '0xbf5140a22578168fd562dccf235e5d43a02ce9b1',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 13,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'LINK',
    lpAddresses: {
      97: '',
      56: '0x00C4849E82D574B02f43c0F84b131dCC9cAbFC49', // chain - busd lp
    },
    tokenSymbol: 'LINK',
    tokenAddresses: {
      97: '',
      56: '0xf8a0bf9cf54bb92f17374d9e9a321e6a111a51bd',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 14,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'C98',
    lpAddresses: {
      97: '',
      56: '0x49fa121aDBF2A17ceE092199d269a94054bEF973', // c98 - busd lp
    },
    tokenSymbol: 'C98',
    tokenAddresses: {
      97: '',
      56: '0xaec945e04baf28b135fa7c640f624f8d90f1c3a6',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 15,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'MOBOX',
    lpAddresses: {
      97: '',
      56: '0x9a4E0660E658E7b4A284079C6C10a5bA74e13926', // mobox - busd lp
    },
    tokenSymbol: 'MOBOX',
    tokenAddresses: {
      97: '',
      56: '0x3203c9e46ca618c8c1ce5dc67e7e9d75f5da2377',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 16,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'SHIB',
    lpAddresses: {
      97: '',
      56: '0xAFED47109dd84bf9067BBd592b2ebb47f82ffa54', // mobox - busd lp
    },
    tokenSymbol: 'SHIB',
    tokenAddresses: {
      97: '',
      56: '0x2859e4544c4bb03966803b044a93563bd2d0dd4d',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 17,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'WINk',
    lpAddresses: {
      97: '',
      56: '0x6a445cEB72c8B1751755386c3990055ff92e14A0', // wink - busd lp
    },
    tokenSymbol: 'WINk',
    tokenAddresses: {
      97: '',
      56: '0xaef0d72a118ce24fee3cd1d43d383897d05b4e99',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 18,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'ETHEREUM',
    lpAddresses: {
      97: '',
      56: '0xd9A0d1F5e02dE2403f68Bb71a15F8847A854b494', // eth - busd lp
    },
    tokenSymbol: 'ETHEREUM',
    tokenAddresses: {
      97: '',
      56: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 19,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'SOLANA',
    lpAddresses: {
      97: '',
      56: '0x40Cc5022c928C8F763b810A90A055856dd99B31c', // solana - busd lp
    },
    tokenSymbol: 'SOLANA',
    tokenAddresses: {
      97: '',
      56: '0x570a5d26f7765ecb712c0924e4de545b89fd43df',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 20,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'BTT',
    lpAddresses: {
      97: '',
      56: '0xdcfbB12DED3FEa12D2A078Bc6324131cD14bF835', // btt - busd lp
    },
    tokenSymbol: 'BTT',
    tokenAddresses: {
      97: '',
      56: '0x8595f9da7b868b1822194faed312235e43007b49',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 21,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'CAKE',
    lpAddresses: {
      97: '',
      56: '0x0eD7e52944161450477ee417DE9Cd3a859b14fD0', // btt - busd lp
    },
    tokenSymbol: 'CAKE',
    tokenAddresses: {
      97: '',
      56: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 22,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'AVAX',
    lpAddresses: {
      97: '',
      56: '0x151268db1579ebC5306D4aAa5DCC627646E6986F', // avax - busd lp
    },
    tokenSymbol: 'AVAX',
    tokenAddresses: {
      97: '',
      56: '0x1ce0c2827e2ef14d5c4f29a091d735a204794041',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 23,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'POOL',
    lpAddresses: {
      97: '',
      56: '0xdBba052A0e577337F22CacBe20Ada6FCe06aF7Fe', // avax - busd lp
    },
    tokenSymbol: 'POOL',
    tokenAddresses: {
      97: '',
      56: '0x30F11795B21b5EFC1A2DeD78d04574d0e36442BD',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 24,
    risk: 3,
    lpSymbol: 'POOL-BNB',
    lpAddresses: {
      97: '',
      56: '0xdBba052A0e577337F22CacBe20Ada6FCe06aF7Fe', // pool - bnb lp
    },
    tokenSymbol: 'POOL',
    tokenAddresses: {
      97: '',
      56: '0x30F11795B21b5EFC1A2DeD78d04574d0e36442BD',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 25,
    risk: 3,
    lpSymbol: 'WPOOL',
    isTokenOnly: true,
    lpAddresses: {
      97: '',
      56: '0x69927d26eace9db7ded624c9e9600c2095518de8', // pool - bnb lp
    },
    tokenSymbol: 'WPOOL',
    tokenAddresses: {
      97: '',
      56: '0x5210c1cca955eaa39498b6e74d58646be007b943',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
]

export default farms
