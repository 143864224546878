import BigNumber from 'bignumber.js'
import { useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import useRefresh from 'hooks/useRefresh'
import { fetchFarmsPublicDataAsync, fetchPoolsPublicDataAsync, fetchPoolsUserDataAsync } from './actions'
import { State, Farm, Pool } from './types'
import { QuoteToken } from '../config/constants/types'
import useGetPriceData from '../components/Menu/getPrice'
import useGetPriceCustom from '../components/Menu/getPriceCustom'

const ZERO = new BigNumber(0)

export const useFetchPublicData = () => {
  const dispatch = useDispatch()
  const { slowRefresh } = useRefresh()
  useEffect(() => {
    dispatch(fetchFarmsPublicDataAsync())
    dispatch(fetchPoolsPublicDataAsync())
  }, [dispatch, slowRefresh])
}

// Farms

export const useFarms = (): Farm[] => {
  const farms = useSelector((state: State) => state.farms.data)
  return farms
}

export const useFarmFromPid = (pid): Farm => {
  const farm = useSelector((state: State) => state.farms.data.find((f) => f.pid === pid))
  return farm
}

export const useFarmFromSymbol = (lpSymbol: string): Farm => {
  const farm = useSelector((state: State) => state.farms.data.find((f) => f.lpSymbol === lpSymbol))
  return farm
}

export const useFarmUser = (pid) => {
  const farm = useFarmFromPid(pid)

  return {
    allowance: farm.userData ? new BigNumber(farm.userData.allowance) : new BigNumber(0),
    tokenBalance: farm.userData ? new BigNumber(farm.userData.tokenBalance) : new BigNumber(0),
    stakedBalance: farm.userData ? new BigNumber(farm.userData.stakedBalance) : new BigNumber(0),
    earnings: farm.userData ? new BigNumber(farm.userData.earnings) : new BigNumber(0),
  }
}


// Pools

export const usePools = (account): Pool[] => {
  const { fastRefresh } = useRefresh()
  const dispatch = useDispatch()
  useEffect(() => {
    if (account) {
      dispatch(fetchPoolsUserDataAsync(account))
    }
  }, [account, dispatch, fastRefresh])

  const pools = useSelector((state: State) => state.pools.data)
  return pools
}

export const usePoolFromPid = (sousId): Pool => {
  const pool = useSelector((state: State) => state.pools.data.find((p) => p.sousId === sousId))
  return pool
}

// Prices

export const usePriceBnbBusd = (): BigNumber => {
  // const pid = 2 // BUSD-BNB LP
  const pid = 0;
  const farm = useFarmFromPid(pid)
    // @ts-ignore  
    const priceData = useGetPriceData()

     // @ts-ignore  
    const priceData2 = new BigNumber(priceData)

    const priceData3 = priceData2.times(1)

    return priceData3
}

export const usePriceCakeBusd = (): BigNumber => {
  // const pid = 1 // CAKE-BNB LP
  const pid = 0;
  // const bnbPriceUSD = usePriceBnbBusd()

  let priceData = useGetPriceData()

  let COTIPRICE = useGetPriceCustom("coti")
  let BTTPRICE = useGetPriceCustom("bittorrent-2")
  let TRX = useGetPriceCustom("tron")
  let XRP = useGetPriceCustom("ripple")
  let ADA = useGetPriceCustom('cardano')
  let SHIBA = useGetPriceCustom('shiba-inu')
  let RACA = useGetPriceCustom('radio-caca')
  let POOL = useGetPriceCustom('pool-token')
  let BCOIN = useGetPriceCustom('bomber-coin')
  let METAHERO = useGetPriceCustom('metahero')
  let GALA = useGetPriceCustom('gala')
  let MATIC = useGetPriceCustom('matic-network')
  // @ts-ignore  
  priceData = new BigNumber(priceData)

  // @ts-ignore  
  COTIPRICE = new BigNumber(COTIPRICE)

  // @ts-ignore  
  BTTPRICE = new BigNumber(BTTPRICE)

  // @ts-ignore  
  TRX = new BigNumber(TRX)

  // @ts-ignore  
  XRP = new BigNumber(XRP)

  // @ts-ignore  
  ADA = new BigNumber(ADA)

  // @ts-ignore  
  SHIBA = new BigNumber(SHIBA)

  // @ts-ignore  
  RACA = new BigNumber(RACA)


  // @ts-ignore  
  POOL = new BigNumber(POOL)

  // @ts-ignore  
  BCOIN = new BigNumber(BCOIN)

  // @ts-ignore
  METAHERO = new BigNumber(METAHERO)

  // @ts-ignore
  GALA = new BigNumber(GALA)

  // @ts-ignore
  MATIC = new BigNumber(MATIC)

  const farm = useFarmFromPid(pid)
  // @ts-ignore
  localStorage.setItem('poolTp', priceData.times(farm.tokenPriceVsQuote).toString());

// @ts-ignore
    localStorage.setItem('COTIPRICE', COTIPRICE.toString());

  // @ts-ignore
  localStorage.setItem('BTTPRICE', BTTPRICE.toString());

  // @ts-ignore
  localStorage.setItem('TRX', TRX.toString());

  // @ts-ignore
  localStorage.setItem('XRP', XRP.toString());


  // @ts-ignore
  localStorage.setItem('ADA', ADA.toString());

  // @ts-ignore
  localStorage.setItem('SHIBA', SHIBA.toString());

  // @ts-ignore
  localStorage.setItem('RACA', RACA.toString());


  // @ts-ignore
  localStorage.setItem('POOL', POOL.toString());

  // @ts-ignore
  localStorage.setItem('BCOIN', BCOIN.toString());

  // @ts-ignore
  localStorage.setItem('METAHERO', METAHERO.toString());

  // @ts-ignore
  localStorage.setItem('GALA', GALA.toString());

  // @ts-ignore
  localStorage.setItem('MATIC', MATIC.toString());

  //  @ts-ignore
  return farm.tokenPriceVsQuote ? priceData.times(farm.tokenPriceVsQuote) : ZERO
  
}

export const useTotalValue = (): BigNumber => {
  const farms = useFarms();
  const bnbPrice = usePriceBnbBusd();
  const cakePrice = usePriceCakeBusd();
  let value = new BigNumber(0);
  for (let i = 0; i < farms.length; i++) {
    const farm = farms[i]
    if (farm.lpTotalInQuoteToken) {
      let val;
      if (farm.quoteTokenSymbol === QuoteToken.BNB) {
        val = (bnbPrice.times(farm.lpTotalInQuoteToken));
      }else if (farm.quoteTokenSymbol === QuoteToken.CAKE) {
        val = (cakePrice.times(farm.lpTotalInQuoteToken));
      }else{
        val = (farm.lpTotalInQuoteToken);
      }
      value = value.plus(val);
    }
  }
  return value;
}

export const useGetApiPrice = (token: string) => {
  // const prices = useGetApiPrices()


  // if (!prices) {
  //   return null
  // }
  let toReturn;
  const thePrice = localStorage.getItem('poolTp');
  const COTIPRICE = localStorage.getItem('COTIPRICE');
  const BTTPRICE = localStorage.getItem('BTTPRICE');
  const TRX = localStorage.getItem('TRX');
  const XRP = localStorage.getItem('XRP');
  const ADA = localStorage.getItem('ADA');
  const SHIBA = localStorage.getItem('SHIBA');
  const RACA = localStorage.getItem('RACA');
  const POOL = localStorage.getItem('POOL');
  const BCOIN = localStorage.getItem('BCOIN');
  const METAHERO = localStorage.getItem('METAHERO');
  const GALA = localStorage.getItem('GALA');
  const MATIC = localStorage.getItem('MATIC');

  switch(token){
    case "MSC":
      toReturn = parseFloat(thePrice);
      break;
    case "COTI":
      // @ts-ignore
      toReturn = parseFloat(COTIPRICE)
      break;
    case "BTT":
     //  @ts-ignore
      toReturn = parseFloat(BTTPRICE)
      break;
    case "TRX":
      //  @ts-ignore
      toReturn = parseFloat(TRX)
      break;
    case "XRP":
      //  @ts-ignore
      toReturn = parseFloat(XRP)
      break;
    case "SHIBA":
      //  @ts-ignore
      toReturn = parseFloat(SHIBA)
      break;
    case "ADA":
      // @ts-ignore
      toReturn = parseFloat(ADA)
      break;
    case "WPOOL":
      // @ts-ignore
      toReturn = parseFloat(POOL)
      break;
    case "WRAPPED POOL":
      // @ts-ignore
      toReturn = parseFloat(POOL)
      break;
    case "RACA":
      // @ts-ignore
      toReturn = parseFloat(RACA)
      break;
    case "BCOIN":
        // @ts-ignore
        toReturn = parseFloat(BCOIN)
        break;
    case "HERO":
        // @ts-ignore
        toReturn = parseFloat(METAHERO)
        break;
    case "GALA":
       // @ts-ignore
       toReturn = parseFloat(GALA)
       break;
    case "MATIC":
      // @ts-ignore
      toReturn = parseFloat(MATIC)
      break;
    default:
      toReturn = null
      break;
  }
  return toReturn
  // return prices[token.toLowerCase()]
}